import React, { Fragment, useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styles from "../assets/css/home.module.css";
import { Link } from "react-router-dom";
import axios from 'axios';
import { FiChevronRight, FiInstagram, FiChevronLeft } from "react-icons/fi";

import { option_one, option_two, option_three } from "../libs/CarouselOptions";
import MyModal from "../components/common/MyModal";
import JoinMission from "../components/homeComponents/JoinMission";

const images = [
  'images/gallery/g-1.jpeg',
  'images/gallery/g-2.jpg',
  'images/gallery/g-3.jpg',
  'images/gallery/g-4.jpg',
  'images/gallery/gl5.jpg',
]

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const { carouselState: { currentSlide } } = rest;
  return (
    <div className="carousel-button-group"> 
      <button className={currentSlide === 0 ? 'disable' : ''} onClick={() => previous()}><FiChevronLeft/></button>
      <button onClick={() => next()}><FiChevronRight/></button>
    </div>
  );
};

const Home = () => {
  const [isOpen, setOpen] = useState(false);
  const [currentModalImage, setCurrentModalImage] = useState(null);
  const [instagramPost, setInstPost] = useState([]);

  const handleModal = (val) =>{
    setCurrentModalImage(val);
    setOpen(true);
  }
  const getInstagramPost = async ()=>{
      try {
        const response = await axios.get('https://maxxsocialwelfare.org/phpmail/instagram.php');
        setInstPost(response.data.data)
      } catch (error) {
        console.error('Error fetching Instagram posts:', error);
      }
  }

  useEffect(()=>{
    getInstagramPost();
  },[]);
  // const [isLoading, setLoading] = useState(true);
  // const [posts, setPosts] = useState([]);
  // useEffect(() => {
  //   const fetchMediumPosts = async () => {
  //     try {
  //       const response = await axios.get('http://localhost:3001/medium-posts');
  //       console.log(response)
       
  //     } catch (error) {
  //       console.error('Error fetching Medium blog posts:', error);
  //     }
  //   };

  //   fetchMediumPosts();
  // }, []);


  return (
    <Fragment>
      <div className={styles.heroBanner}>
        <Carousel
          responsive={option_one}
          showDots={true}
          infinite={true}
          autoPlay={true}
          ssr={true}
          className="heroBnr"

          removeArrowOnDeviceType={[
            "superLargeDesktop",
            "desktop",
            "tablet",
            "mobile",
          ]}
        >
          <div
            className={styles.bannerItem}
            style={{
              backgroundImage: `url('/images/banner1.jpg')`,
            }}
          >
            <div className={styles.bannerCaption}>
              <div className={styles.bannerCaptionInner}>
                
                <h1>Inspiring   <br /> Tomorrow's Champions Today</h1>
                <a href={"https://docs.google.com/forms/d/e/1FAIpQLSc69uIzIXehks6PQDVVKW2sJQNILyBzPl3zbzTc5zqLt7sSvg/viewform"} target="_black" rel="noreferrer">Partner with Us</a>
              </div>
            </div>
          </div>
          <div
            className={styles.bannerItem}
            style={{
              backgroundImage: `url('/images/banner1.jpg')`,
            }}
          >
            <div className={styles.bannerCaption}>
              <div className={styles.bannerCaptionInner}>
                
                <h1>Be a Part of  <br /> the Change.</h1>
                <a href={"https://docs.google.com/forms/d/e/1FAIpQLSc69uIzIXehks6PQDVVKW2sJQNILyBzPl3zbzTc5zqLt7sSvg/viewform"} target="_black" rel="noreferrer">Partner with Us</a>
              </div>
            </div>
          </div>
          <div
            className={styles.bannerItem}
            style={{
              backgroundImage: `url('/images/banner1.jpg')`,
            }}
          >
            <div className={styles.bannerCaption}>
              <div className={styles.bannerCaptionInner}>
                
                <h1> Empowered <br /> Girls, Empowered <br /> Future!</h1>
                <a href={"https://docs.google.com/forms/d/e/1FAIpQLSc69uIzIXehks6PQDVVKW2sJQNILyBzPl3zbzTc5zqLt7sSvg/viewform"} target="_black" rel="noreferrer">Partner with Us</a>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
      <div className={styles.visionWrapper}>
        <div className="container-fluid">
          <div className="row">
            <div className=" col-md-4 pd-0">
              <img src="/images/gl-1.jpg" alt="gl-1" />
            </div>
            <div className=" col-md-4 pd-0 redBg">
              <div className={styles.visionBoxContent}>
                <h3>Vision</h3>
                <hr style={{ color: '#009368' }} />
                <p>To be a dynamic force of positive change, utilizing sports, health, physical education, self-defense, and mind sports to cultivate healthier, more active, and empowered individuals and communities. Our vision encompasses a world where people of all ages and backgrounds have access to exceptional sports, 
comprehensive physical education, essential self-defense training, and stimulating mind sports. By 
ardently pursuing our mission, pioneering innovative programs, and forming impactful partnerships, we 
envision a future where lives are enriched, holistic wellness is cherished, and communities are united in strength and mental acumen.</p>
              </div>
            </div>
            <div className=" col-md-4 pd-0 blueBg">
              <div className={styles.visionBoxContent}>
                <h3>Mission</h3>
                <hr style={{ color: '#fcad30' }} />
                <p>Empowering communities through sports, health, physical education, self-defense, and mind sports, 
fostering holistic well-being, inclusivity, and lifelong learning.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.aboutSection}>
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <img src="/images/about.png" alt="abt-img" />
            </div>
            <div className="col-md-7">
              <div className={styles.aboutContent}>
                <div className={styles.aboutContentTitle}>
                  <span>About Us</span>
                  <h3>Welcome to Maxx Social Welfare Foundation</h3>
                  <hr style={{ color: '#fcad30' }} />
                </div>

                <p>At the heart of our organization lies a profound commitment to empowerment, well-being, and positive transformation. With an unwavering dedication to fostering holistic growth, inclusivity, and lifelong learning, we stand as a beacon of change in the realm of sports and wellness. Our mission and vision serve as guiding lights, propelling us toward a future where individuals and communities thrive through sports, health, physical education, self-defense, and mind sports. As we passionately pursue our goals, we striveto create a world where access to comprehensive education, essential skills, and enriching experiences knows no bounds. Join us on this remarkable journey as we work tirelessly to make a difference, nurturing healthier, more active, and empowered lives for all.</p>
                <Link to={'/'} className="button-1 ">About Us</Link>
              </div>
            </div>

          </div>

        </div>
      </div>
      <div className={styles.focusAreas}>
        <div className="section-title">
          <h2>Foundation Focus Areas</h2>
          <hr />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-6 col-md-2">
              <div className={styles.iconBoxWrapper}>
                <div className={styles.iconBox}>
                  <img src="/images/Ficon-2.png" alt="fl" />
                </div>
                <span className={styles.iconTitle}>Sports</span>
              </div>
            </div>
            <div className="col-6 col-md-2">
              <div className={styles.iconBoxWrapper}>
                <div className={styles.iconBox}>
                  <img src="/images/Ficon-6.png" alt="fl" />
                </div>
                <span className={styles.iconTitle}>Health</span>
              </div>
            </div>
            <div className="col-6 col-md-2">
              <div className={styles.iconBoxWrapper}>
                <div className={styles.iconBox}>
                  <img src="/images/Ficon-3.png" alt="fl" />
                </div>
                <span className={styles.iconTitle}>Self Defence</span>
              </div>
            </div>
            <div className="col-6 col-md-2">
              <div className={styles.iconBoxWrapper}>
                <div className={styles.iconBox}>
                  <img src="/images/Ficon-4.png" alt="fl" />
                </div>
                <span className={styles.iconTitle}>Physical Education</span>
              </div>
            </div>
            <div className="col-6 col-md-2">
              <div className={styles.iconBoxWrapper}>
                <div className={styles.iconBox}>
                  <img src="/images/Ficon-5.png" alt="fl" />
                </div>
                <span className={styles.iconTitle}>Mind Sports</span>
              </div>
            </div>
            <div className="col-6 col-md-2">
              <div className={styles.iconBoxWrapper}>
                <div className={styles.iconBox}>
                  <img src="/images/Ficon-1.png" alt="fl" />
                </div>
                <span className={styles.iconTitle}>Women Empowerment</span>
              </div>
            </div>
          </div>
        </div>
      </div>
   

     <JoinMission/>
      <div className={styles.gallery}>
        <div className="section-title">
          <h2>Gallery</h2>
          <hr />
        </div>
        <div className="container gallery-container">
        <div className="row">
        <Carousel
          responsive={option_two}
          infinite={true}
          autoPlay={false}
          ssr={true}
          className="galleryBox"
          itemClass={styles.carouselItem} 
          partialVisible={false}
          arrows={false} 
          renderButtonGroupOutside={true} 
          customButtonGroup={<ButtonGroup />}
          // removeArrowOnDeviceType={[
          //   "tablet",
          //   "mobile",
          // ]}
        >
          {
            images.map((item, i)=>{
              return  <div className="">
              <div className={styles.galleryItem} key={item} onClick={() => handleModal(item)}>
                <img src={item} alt="img" />
              </div>
            </div>
            })
          }
         
        
        </Carousel>
        </div>
         
        </div>
      </div>
      {/* <div className={styles.ourBlog}>
        <div className="container">
        <div className="section-title-3">
          <span><img src='/images/heart3.png' alt='heart' /> Blog Posts</span>
          <h2>Latest news & articles<br/>
            directly from the blog.</h2>
        </div>
         <div className={styles.postList}>
            <div className="row">
                <div className="col-md-4">
                   <div className={styles.postItem}>
                      <div className={styles.postThumb}>
                         <img src="/images/postth1.jpg" alt="post_thumb"/>
                         <div className={styles.postDate}>
                            20 May 
                         </div>
                      </div>
                      <div className={styles.postExcerpt}>
                          <div className={styles.postExcerptHead}>
                              <ul>
                              <li><FiMail size={18} color="#fcad30"/> Admin</li>
                                  <li><FaComments size={18} color="#fcad30"/> Comments</li>
                              </ul>
                          </div>
                          <h3>Our donation is hope for poor childrens</h3>
                      </div>
                      <div className={styles.postActions}>
                           <Link to={'/'}> <FiChevronRight size={20} color="#7e7e7e"/>  Read More</Link>
                      </div>
                   </div>
                </div>
                <div className="col-md-4">
                   <div className={styles.postItem}>
                      <div className={styles.postThumb}>
                         <img src="/images/postth2.jpg" alt="post_thumb"/>
                         <div className={styles.postDate}>
                            20 May 
                         </div>
                      </div>
                      <div className={styles.postExcerpt}>
                          <div className={styles.postExcerptHead}>
                              <ul>
                              <li><FiMail size={18} color="#fcad30"/> Admin</li>
                                  <li><FaComments size={18} color="#fcad30"/> Comments</li>
                              </ul>
                          </div>
                          <h3>Our donation is hope for poor childrens</h3>
                      </div>
                      <div className={styles.postActions}>
                           <Link to={'/'}> <FiChevronRight size={20} color="#7e7e7e"/>  Read More</Link>
                      </div>
                   </div>
                </div>
                <div className="col-md-4">
                   <div className={styles.postItem}>
                      <div className={styles.postThumb}>
                         <img src="/images/postth3.jpg" alt="post_thumb"/>
                         <div className={styles.postDate}>
                            20 May 
                         </div>
                      </div>
                      <div className={styles.postExcerpt}>
                          <div className={styles.postExcerptHead}>
                              <ul>
                                  <li><FiMail size={18} color="#fcad30"/> Admin</li>
                                  <li><FaComments size={18} color="#fcad30"/> Comments</li>
                              </ul>
                          </div>
                          <h3>Our donation is hope for poor childrens</h3>
                      </div>
                      <div className={styles.postActions}>
                           <Link to={'/'}> <FiChevronRight size={20} color="#7e7e7e"/>  Read More</Link>
                      </div>
                   </div>
                </div>
            </div>
         </div>
        </div>
      
      </div> */}
      <div className={styles.instagramPost}>
      <div className="section-title">
          <span>Follow On</span>
          <h2>Our Instagram</h2>
          <hr />
        </div>
        <div className="container insta-container">
            <Carousel
          responsive={option_three}
          showDots={false}
          infinite={true}
          autoPlay={false}
          ssr={true}
          className="instagramPost"
          arrows={false} 
          partialVisible={false}
          itemClass={styles.carouselItem}
          renderButtonGroupOutside={true} 
          customButtonGroup={<ButtonGroup />}
        >
          {
             instagramPost.length > 0 && instagramPost.map((post)=>{ 
              if(post.media_type === 'VIDEO'){
                return null;
               }
               return  <div key={post.id} className="instagram-post">
                <a href={post.permalink} target="_blank" rel="noopener noreferrer">
                    <img src={post.media_url} alt={'insta media'} /> 
                </a>
               </div>
              })
          }
       
        </Carousel>
        </div>
        <div className={styles.moreInstagram}>
           <a href={'https://www.instagram.com/maxxsocialwelfare/'}  target="_blank" rel="noopener noreferrer"><FiInstagram size={18} color="#fff" /> View on Instagram</a>
        </div>
      </div>

      <MyModal isOpen={isOpen} closeModal={() => setOpen(false)} fullWidth={true}>
           <img src={currentModalImage} alt="img" style={{width:'auto', maxHeight:550}}/>
        </MyModal>
    </Fragment>
  );
};

export default Home;
